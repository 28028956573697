





















































import Vue from 'vue';
import { NotificationMessageSender } from '../model/notificationMessage';

export default Vue.extend({
  name: 'NotificationMessageListFilter',
  props: ['disabled', 'searchFilter'],
  data() {
    return {
      NotificationMessageSender,
    };
  },
  methods: {
    search(): void {
      this.$emit('getList');
    },
  },
});
