import { PageInfo } from '@frientrip/domain';

export interface User {
  id: number | null;
  nickname: string | null;
}

export interface PointsRequestParam {
  page?: number;
  size?: number;
  filter?: PointFilter;
}

export interface PointFilter {
  id?: number | null;
  userId?: number | null;
  refType?: PointRefType | null;
  refId?: string | null;
  typeId?: number | null;
  kind?: PointKind | null;
  cancelled?: boolean | null;
  accountingType?: PointAccountingType | null;
}

export enum PointRefType {
  PURCHASE = 'PURCHASE',
  ATTENDANCE = 'ATTENDANCE',
  PURCHASED_ITEM = 'PURCHASED_ITEM',
  POINT = 'POINT',
  PRODUCT = 'PRODUCT',
  SERIAL = 'SERIAL',
  CUSTOMER = 'CUSTOMER',
  MANUALLY_GIVE_POINT = 'MANUALLY_GIVE_POINT',
  REVIEW = 'REVIEW',
  POINT_COUPON_VALUE = 'POINT_COUPON_VALUE',
}

export enum PointKind {
  SAVED = 'SAVED',
  USED = 'USED',
  EXPIRED = 'EXPIRED',
}

export enum PointAccountingType {
  PROMOTION = 'PROMOTION',
  WELFARE = 'WELFARE',
  BUSINESS = 'BUSINESS',
  NONE = 'NONE',
}

export interface PointType {
  id: number;
  name: string;
  label?: string | null;
  code: string;
  onlyAdmin: boolean;
  status: string;
  worker: User;
}

export interface PointEdge {
  node: PointAPIResponse;
  cursor: string;
}

export interface PointConnection {
  edges: PointEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface PointAPIResponse {
  id: number;
  kind: PointKind;
  type: PointType;
  amount: number;
  usedAmount: number | null;
  refType: PointRefType | null;
  refId: number | null;
  user: User;
  title: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date | null;
  appliedAt: Date | null;
  expiredAt: Date | null;
  accountingType: PointAccountingType;
  worker: User | null;
  cancelled: boolean;
  deleted: boolean;
  deletedAt: Date | null;
  usedHistories: UsedHistory[];
  savedLinks: SavedLink[];
}

export interface UsedHistory {
  amount: number;
  used: PointHistory;
}

export interface SavedLink {
  amount: number;
  saved: PointHistory;
}

export interface PointHistory {
  id: number;
  kind: PointKind;
  type: PointType;
  amount: number;
  usedAmount: number | null;
  refType: PointRefType | null;
  refId: number | null;
  title: string;
  description: string | null;
  appliedAt: Date | null;
  expiredAt: Date | null;
  accountingType: PointAccountingType;
}
