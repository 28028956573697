import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import axios from 'axios';
import {
  ManuallyGivePointParam,
  ManuallyGivePointAPIResponse,
  FileUploadInfo,
} from '../model/manuallyGivePoint';
import {
  GENERATE_S3_UPLOAD_INFO_MUTATION,
  SAVING_MANUALLY_GIVE_POINT_MUTATION,
} from '../queries/mutation';
import { GET_RECEIVED_USER_IDS_QUERY } from '../queries/query';

export class PointService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  public async savingMauallyGivePoint(
    param: ManuallyGivePointParam
  ): Promise<ManuallyGivePointAPIResponse> {
    const savingMauallyGivePointResult = await this.apollo.mutate({
      mutation: SAVING_MANUALLY_GIVE_POINT_MUTATION,
      variables: {
        param,
      },
    });
    return savingMauallyGivePointResult.data.savingMauallyGivePoint;
  }

  public async uploadFile(file: File): Promise<string> {
    const uploadInfo: FileUploadInfo = await this.generateS3UploadInfo(
      file.name
    );

    await axios({
      url: uploadInfo.url,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    return uploadInfo.key;
  }

  private async generateS3UploadInfo(
    filename: string
  ): Promise<FileUploadInfo> {
    const generateS3UploadInfoResult = await this.apollo.mutate({
      mutation: GENERATE_S3_UPLOAD_INFO_MUTATION,
      variables: {
        filename,
      },
    });

    return generateS3UploadInfoResult.data.generateS3UploadInfo;
  }

  public async getReceivedUserIds(
    manuallyGivePointId: number
  ): Promise<number[]> {
    const result = await this.apollo.query({
      query: GET_RECEIVED_USER_IDS_QUERY,
      variables: {
        manuallyGivePointId,
      },
    });

    return result.data.point.receivedUserIds;
  }
}
