import { PageInfo } from '@frientrip/domain';
import { PointAccountingType, User } from './point';

export interface ManuallyGivePointParam {
  memo: string;
  target?: ManuallySavingPointParam;
  targetS3ObjectKey?: string;
}

export interface ManuallySavingPointParam {
  userId: number;
  amount: number;
  description: string;
  appliedAt: Date;
  expiredAt: Date;
  accountingType: PointAccountingType;
}

export interface ManuallyGivePointEdge {
  node: ManuallyGivePointAPIResponse;
  cursor: string;
}

export interface ManuallyGivePointConnection {
  edges: ManuallyGivePointEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export enum PointProgressState {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export interface ManuallyGivePointFilter {
  workerId?: number | null;
  memoLike?: string | null;
}

export interface ManuallyGivePointAPIResponse {
  id: number;
  memo: string;
  worker: User;
  createdAt: Date;
  updatedAt: Date;
  status: PointProgressState;
  reason: string | null;
  points: {
    totalCount: number;
  };
  appPushLogs: AppPushLog[];
}

export interface FileUploadInfo {
  type: string;
  url: string;
  key: string;
}

export interface AppPushLog {
  push: AppPush;
}

export interface AppPush {
  target?: AppPushTarget | null;
  payload: AppPushPayload;
}

export interface AppPushTarget {
  id?: string | null;
  target?: string | null;
}

export interface AppPushPayload {
  title: string;
  body: string;
  deeplink: string;
  image?: string | null;
}
