var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":_vm.title,"subtitle":_vm.totalRows.toString(),"notification":"지급 완료 수량을 누르면 에너지 내역으로 이동합니다."},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])},[_c('div',{staticClass:"text-right m-3"},[_c('frip-button',{attrs:{"type":"secondary","size":"sm","disabled":_vm.disabled || _vm.$apollo.loading},on:{"click":_vm.getList}},[_vm._v("새로고침")]),_c('frip-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:("give-point-filter-collapse"),expression:"`give-point-filter-collapse`"}],attrs:{"type":"primary","size":"sm"}},[_vm._v("필터 열기")])],1),_c('b-collapse',{attrs:{"id":"give-point-filter-collapse"}},[_c('give-point-list-filter',{staticClass:"mb-4",attrs:{"searchFilter":_vm.searchFilter,"disabled":_vm.disabled},on:{"getList":_vm.getList}})],1),_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.manuallyGivePoints}},[_c('el-table-column',{attrs:{"label":"id","prop":"id","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"지급 사유","prop":"memo","min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.memo)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"지급한 관리자 정보","prop":"worker","min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(((row.worker.nickname) + " (" + (row.worker.id) + ")"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"지급 상태","prop":"kind","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.convertProgressState(row.status, row.reason))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"푸시","prop":"kind","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === _vm.PointProgressState.DONE)?_c('div',[(row.appPushLogs.length === 0)?_c('frip-button',{attrs:{"type":"primary","disabled":_vm.disabled || _vm.$apollo.loading,"size":"sm"},on:{"click":function($event){return _vm.sendPush(row)}}},[_vm._v(" 푸시 발송 ")]):_c('div',[_vm._v("발송 요청 완료")])],1):_c('div',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"지급일시","prop":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('instant',{attrs:{"at":row.createdAt}})]}}])}),_c('el-table-column',{attrs:{"label":"지급 완료 수량","prop":"pointCount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":_vm.$wlink.pointByReference(row.id, 'MANUALLY_GIVE_POINT')}},[_vm._v(" "+_vm._s(row.pointCount))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }