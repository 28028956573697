import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { AppPushParam } from '@/common/model/AppPushParam';
import { SEND_APP_PUSH } from '@/domain/coupon/queries/sendPushMutation';

export class SendAppPushService {
  protected readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async sendAppPush(
    param: AppPushParam,
    customerIds: string[]
  ): Promise<{ success: boolean; message: string }> {
    const result = await this.apollo.mutate({
      mutation: SEND_APP_PUSH,
      variables: {
        pushParam: param,
        customerIds,
      },
    });

    return result.data.sendAppPush;
  }
}
