












































































































import Vue from 'vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Table, TableColumn } from 'element-ui';
import { GET_MANUALLY_GIVE_POINTS_QUERY } from '../queries/query';
import {
  ManuallyGivePointAPIResponse,
  ManuallyGivePointConnection,
  ManuallyGivePointEdge,
  ManuallyGivePointFilter,
  PointProgressState,
} from '../model/manuallyGivePoint';
import Instant from '@/components/Labels/Instant.vue';
import GivePointListFilter from '../components/GivePointListFilter.vue';
import { SendAppPushService } from '@/common/service/SendAppPushService';
import { apolloClient } from '@/apolloClient';
import { PointService } from '../service/PointService';
import { AppPushParam } from '@/common/model/AppPushParam';

const pointService = new PointService(apolloClient);
const sendAppPushService = new SendAppPushService(apolloClient);

interface ManuallyGivePointsRequestParam {
  page?: number;
  size?: number;
  filter?: ManuallyGivePointFilter;
}

export default Vue.extend({
  name: 'GivePointList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Instant,
    GivePointListFilter,
  },
  data() {
    return {
      point: { manuallyGivePoints: { edges: [] as ManuallyGivePointEdge[] } },
      title: '에너지 수동 지급 내역',
      page: this.$route.query.page || 1,
      size: this.$route.query.pageSize || 10,
      PointProgressState,
      disabled: false,
      totalRows: 0,
      searchFilter: {} as ManuallyGivePointFilter,
      filter: {} as ManuallyGivePointFilter,
    };
  },
  computed: {
    manuallyGivePoints(): ManuallyGivePointAPIResponse[] {
      if (this.point.manuallyGivePoints.edges) {
        return this.point.manuallyGivePoints.edges.map(edge => edge.node);
      } else {
        return [];
      }
    },
  },
  activated: async function (): Promise<void> {
    this.page = this.$route.query.page || 1;
    this.size = this.$route.query.pageSize || 30;
    this.searchFilter = {
      memoLike: this.$route.query?.memoLike?.toString() || undefined,
      workerId: Number(this.$route.query?.workerId) || undefined,
    };
    await this.getList();
  },
  methods: {
    async resetSearchFilter(): Promise<void> {
      this.page = 1;
      this.size = 30;
      this.searchFilter = {};
      await this.getList();
    },
    async getList(): Promise<void> {
      try {
        this.disabled = true;
        this.filter = {
          memoLike: this.searchFilter?.memoLike?.trim(),
          workerId: Number(this.searchFilter?.workerId) || undefined,
        };
        await this.$apollo.queries.point.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `에너지 수동 지급 내역을 불러오는데 실패하였습니다. (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    convertProgressState(
      status: PointProgressState,
      reason: string | null
    ): string {
      if (status == PointProgressState.REQUESTED) {
        return `요청됨`;
      } else if (status == PointProgressState.IN_PROGRESS) {
        return `처리중`;
      } else if (status == PointProgressState.DONE) {
        return `지급 완료`;
      } else {
        return `지급 실패 (${reason})`;
      }
    },
    pageClick(bvEvent: any, page: number): void {
      if (
        this.$route.path !==
        `/points/give/list?page=${page}&pageSize=${this.size}`
      ) {
        this.$router.push(
          `/points/give/list?page=${page}&pageSize=${this.size}`
        );
      }
    },
    async sendPush(row: ManuallyGivePointAPIResponse): Promise<void> {
      this.$modal.show(
        {
          title: '푸시 발송',
          message: '에너지 지급 안내 푸시 메시지를 발송하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          this.disabled = true;
          try {
            const userIds = await pointService.getReceivedUserIds(row.id);
            console.log(`userId: ${JSON.stringify(userIds)}`);

            if (userIds.length > 1000) {
              this.$modal.show({
                title: `발송 실패!`,
                message: `10,000명을 초과한 대상에게 푸시를 보낼 수 없습니다.`,
                type: `danger`,
              });
              this.disabled = false;
              return;
            }

            const pushParam: AppPushParam = {
              targetId: row.id.toString(),
              targetType: 'ManuallyGivePoint',
              title: `에너지가 지급되었어요!`,
              body: `마이 > 에너지에서 확인 가능하며, 프립 결제 시 사용하실 수 있습니다.`,
              deeplink: 'energies',
            };

            const appPushResponse = await sendAppPushService.sendAppPush(
              pushParam,
              userIds.map(userId => userId.toString())
            );

            if (!appPushResponse.success) {
              this.$modal.show({
                title: `발송 실패!`,
                message: appPushResponse.message,
                type: `danger`,
              });
              this.disabled = false;
              return;
            }

            await this.getList();
          } catch (err) {
            let errorMessage = `알수없는 오류`;
            if (err instanceof Error) {
              errorMessage = err.message;
            }
            this.disabled = false;
            this.$modal.show({
              title: `푸시 발송 실패!`,
              message: errorMessage,
              type: `danger`,
            });
          }
        }
      );
    },
  },
  apollo: {
    point: {
      query: GET_MANUALLY_GIVE_POINTS_QUERY,
      variables(): ManuallyGivePointsRequestParam {
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        this.disabled = false;
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          point: { manuallyGivePoints: ManuallyGivePointConnection };
        }>
      ): void {
        this.disabled = false;
        this.totalRows = result.data.point.manuallyGivePoints.totalCount;
      },
      update: data => data.point,
    },
  },
});
