import gql from 'graphql-tag';

export const SEND_APP_PUSH = gql`
  mutation sendAppPush($pushParam: AppPushParam!, $customerIds: [String!]!) {
    sendAppPush(pushParam: $pushParam, customerIds: $customerIds) {
      success
      message
    }
  }
`;
