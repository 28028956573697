




import Vue from 'vue';
export default Vue.extend({
  name: 'Instant',
  props: {
    at: {
      type: Number,
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatShort: 'YYYY-MM-DD',
    };
  },
});
