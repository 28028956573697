import gql from 'graphql-tag';

export const GET_MANUALLY_GIVE_POINTS_QUERY = gql`
  query GetManuallyGivePoints(
    $page: Int
    $size: Int
    $filter: ManuallyGivePointFilter
  ) {
    point {
      manuallyGivePoints(page: $page, size: $size, filter: $filter) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            memo
            worker {
              id
              nickname
            }
            createdAt
            updatedAt
            status
            reason
            pointCount
            appPushLogs {
              push {
                target {
                  id
                  type
                }
                payload {
                  title
                  body
                  deeplink
                  image
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_POINTS_QUERY = gql`
  query GetPoints($page: Int, $size: Int, $filter: PointFilter) {
    point {
      points(page: $page, size: $size, filter: $filter) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            kind
            type {
              id
              name
              label
              code
              onlyAdmin
              status
              # worker {
              #   id
              #   name
              # }
            }
            amount
            usedAmount
            refType
            refId
            user {
              id
              nickname
            }
            title
            description
            createdAt
            updatedAt
            appliedAt
            expiredAt
            accountingType
            worker {
              id
              nickname
            }
            cancelled
            deleted
            deletedAt
            usedHistories {
              amount
              used {
                id
                kind
                type {
                  id
                  name
                  label
                  code
                  onlyAdmin
                  status
                }
                amount
                usedAmount
                refType
                refId
                title
                description
                appliedAt
                expiredAt
                accountingType
              }
            }
            savedLinks {
              amount
              saved {
                id
                kind
                type {
                  id
                  name
                  label
                  code
                  onlyAdmin
                  status
                }
                amount
                usedAmount
                refType
                refId
                title
                description
                appliedAt
                expiredAt
                accountingType
              }
            }
          }
        }
      }
    }
  }
`;

export const POINT_TYPES_QUERY = gql`
  query GetTypes($page: Int, $size: Int, $filter: PointTypeFilter) {
    point {
      types(page: $page, size: $size, filter: $filter) {
        totalCount
        edges {
          node {
            id
            name
            code
            label
            status
          }
        }
      }
    }
  }
`;

export const GET_RECEIVED_USER_IDS_QUERY = gql`
  query GetReceivedUserIds($manuallyGivePointId: ID!) {
    point {
      receivedUserIds(manuallyGivePointId: $manuallyGivePointId)
    }
  }
`;
