




























import Vue from 'vue';

export default Vue.extend({
  name: 'GivePointListFilter',
  props: ['disabled', 'searchFilter'],
  data() {
    return {
      memoLike: '',
      workerId: '',
    };
  },
  methods: {
    search(): void {
      this.$emit('getList');
    },
  },
});
