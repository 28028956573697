















































































































import Vue, { VueConstructor } from 'vue';
import { Table, TableColumn } from 'element-ui';
import { PointAccountingType } from '../model/point';
import { PointService } from '../service/PointService';
import { apolloClient } from '@/apolloClient';
import { ManuallyGivePointParam } from '../model/manuallyGivePoint';

const pointService = new PointService(apolloClient);

enum GiveType {
  SINGLE,
  BULK_INSERT,
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: any } }>
).extend({
  name: 'GivePoint',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      title: '에너지 수동 지급',
      GiveType,
      giveTypeValue: GiveType.SINGLE,
      loading: false,
      form: {
        memo: '',
        target: {
          userId: '',
          amount: '',
          description: '',
          usablePeriod: {
            startedAt: new Date(),
            endedAt: null,
          },
          accountingType: PointAccountingType.BUSINESS,
        },
      },
      file: null as File | null,
      giveTypeOptions: [
        { value: GiveType.SINGLE, text: '1건 지급' },
        { value: GiveType.BULK_INSERT, text: '대량 지급 (2건 이상)' },
      ],
      accountingTypeOptions: [
        { value: PointAccountingType.PROMOTION, text: '광고선전비 (마케팅비)' },
        { value: PointAccountingType.WELFARE, text: '복리후생비' },
        { value: PointAccountingType.BUSINESS, text: '그 외' },
      ],
    };
  },
  computed: {
    disabled: function (): boolean {
      if (this.form.memo.trim() === '') {
        return true;
      }

      if (this.giveTypeValue === GiveType.SINGLE) {
        if (isNaN(parseInt(this.form.target.userId))) {
          return true;
        }
        if (isNaN(parseInt(this.form.target.amount))) {
          return true;
        }
        if (this.form.target.description.trim() === '') {
          return true;
        }
        if (!this.form.target.usablePeriod.startedAt) {
          return true;
        }
        if (!this.form.target.usablePeriod.endedAt) {
          return true;
        }
      } else {
        if (!this.file) {
          return true;
        }
      }
      return false;
    },
  },
  activated: function (): void {
    this.formInit();
  },
  methods: {
    onInput(): void {
      this.$emit('input', this.form);
    },
    formInit(): void {
      this.$refs.formValidator.reset();
      this.form = {
        memo: '',
        target: {
          userId: '',
          amount: '',
          description: '',
          usablePeriod: {
            startedAt: new Date(),
            endedAt: null,
          },
          accountingType: PointAccountingType.BUSINESS,
        },
      };
      this.file = null;
    },
    async savingMauallyGivePoint(): Promise<void> {
      this.loading = true;

      const target = {
        userId: parseInt(this.form.target.userId),
        amount: parseInt(this.form.target.amount),
        description: this.form.target.description,
        appliedAt: this.$moment(this.form.target.usablePeriod.startedAt)
          .startOf('d')
          .toDate(),
        expiredAt: this.$moment(this.form.target.usablePeriod.endedAt)
          .endOf('d')
          .add(-999, 'ms')
          .toDate(),
        accountingType: this.form.target.accountingType,
      };

      try {
        const param: ManuallyGivePointParam = {
          memo: this.form.memo,
          target,
        };

        await pointService.savingMauallyGivePoint(param);
        await this.$router.push(`/points/give/list`);
      } catch (error) {
        console.error(JSON.stringify(error));
        this.$modal.show({
          title: `1건 지급 실패`,
          message: error.networkError.result.errors[0].message,
          type: `danger`,
        });
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async savingMauallyGivePointBulkInsert(): Promise<void> {
      this.loading = true;
      let targetS3ObjectKey = '';

      if (this.file === null) {
        // 파일 없을 때 처리
        this.$modal.show({
          title: `대량 지급 실패`,
          message: `대량 지급용 파일을 선택해주세요.`,
          type: `danger`,
        });

        this.loading = false;
        return;
      }

      if (this.file.name.slice(-3) !== `csv`) {
        // csv 파일 아닐때 처리
        this.$modal.show({
          title: `대량 지급 실패`,
          message: `대량 지급용 파일의 확장자 및 형식이 csv인지 확인해주세요. (선택한 파일 형식: ${this.file.type})`,
          type: `danger`,
        });

        this.loading = false;
        return;
      }

      try {
        targetS3ObjectKey = await pointService.uploadFile(this.file);
      } catch (error) {
        this.loading = false;
        console.error(JSON.stringify(error));
        this.$modal.show({
          title: `대량 지급 실패 (파일 업로드 에러)`,
          message: error.networkError.result.errors[0].message,
          type: `danger`,
        });
        throw error;
      }

      try {
        const param: ManuallyGivePointParam = {
          memo: this.form.memo,
          targetS3ObjectKey,
        };

        await pointService.savingMauallyGivePoint(param);
        await this.$router.push(`/points/give/list`);
      } catch (error) {
        console.error(JSON.stringify(error));
        this.$modal.show({
          title: `지급 실패`,
          message: error.networkError.result.errors[0].message,
          type: `danger`,
        });
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
