











































































































































































































































































import Vue from 'vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Table, TableColumn } from 'element-ui';
import { GET_NOTIFICATION_MESSAGES_QUERY } from '../queries/query';
import {
  MnWiseMessageAPIResponse,
  MnWiseMessageConnection,
  MnWiseMessageEdge,
  MnWiseMessageFilter,
  MnWiseMessageRecord,
  MnWiseMessageRetryState,
  NotificationMessageSender,
} from '../model/notificationMessage';
import NotificationMessageListFilter from '../components/NotificationMessageListFilter.vue';

interface MnWiseMessagesRequestParam {
  page?: number;
  size?: number;
  filter?: MnWiseMessageFilter;
}

export default Vue.extend({
  name: 'NotificationMessageList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NotificationMessageListFilter,
  },
  data() {
    return {
      mnWise: {
        mnWiseMessages: { edges: [] as MnWiseMessageEdge[] },
      },
      title: '알림톡 내역',
      page: this.$route.query.page || 1,
      size: this.$route.query.pageSize || 30,
      totalRows: 0,
      disabled: false,
      NotificationMessageSender,
      showMnWiseMessageDetail: false,
      mnWiseMessageDetailModalData: {},
      searchFilter: {
        createdAtTerm: {
          startedAt: this.$moment().add(-30, 'd').startOf('d').toDate(),
          endedAt: this.$moment().endOf('d').toDate(),
        },
        userId: '',
        hostId: '',
        mobileNumber: '',
        messageLike: '',
        sender: this.$route.path.includes('host')
          ? NotificationMessageSender.FRIP_HOST
          : NotificationMessageSender.FRIP,
      },
      filter: {
        createdAtTerm: {
          startedAt: this.$moment().add(-30, 'd').startOf('d').toDate(),
          endedAt: this.$moment().endOf('d').toDate(),
        },
        sender: this.$route.path.includes('host')
          ? NotificationMessageSender.FRIP_HOST
          : NotificationMessageSender.FRIP,
      } as MnWiseMessageFilter,
    };
  },
  computed: {
    mnWiseMessages(): MnWiseMessageAPIResponse[] {
      if (this.mnWise.mnWiseMessages.edges) {
        return this.mnWise.mnWiseMessages.edges.map(edge => edge.node);
      } else {
        return [];
      }
    },
  },
  watch: {
    '$route.meta.sender': async function (newValue: string): Promise<void> {
      this.init();
      await this.getList();
    },
  },
  activated: async function (): Promise<void> {
    this.init();
    await this.getList();
  },
  methods: {
    pageClick(bvEvent: any, page: number): void {
      if (
        this.$route.path !==
        `${this.$route.path}?page=${page}&pageSize=${this.size}`
      ) {
        this.$router.push(
          `${this.$route.path}?page=${page}&pageSize=${this.size}`
        );
      }
    },
    async init(): Promise<void> {
      this.page = this.$route.query.page || 1;
      this.size = this.$route.query.pageSize || 30;
      this.searchFilter = {
        createdAtTerm: {
          startedAt: this.$moment().add(-30, 'd').startOf('d').toDate(),
          endedAt: this.$moment().endOf('d').toDate(),
        },
        userId: this.$route.query?.userId?.toString() || '',
        hostId: this.$route.query?.hostId?.toString() || '',
        mobileNumber: this.$route.query?.mobileNumber?.toString() || '',
        messageLike: this.$route.query?.messageLike?.toString() || '',
        sender: this.$route.path.includes('host')
          ? NotificationMessageSender.FRIP_HOST
          : NotificationMessageSender.FRIP,
      };
    },
    async resetSearchFilter(): Promise<void> {
      this.page = 1;
      this.size = 30;
      this.searchFilter = {
        createdAtTerm: {
          startedAt: this.$moment().add(-30, 'd').startOf('d').toDate(),
          endedAt: this.$moment().endOf('d').toDate(),
        },
        userId: '',
        hostId: '',
        mobileNumber: '',
        messageLike: '',
        sender: this.$route.path.includes('host')
          ? NotificationMessageSender.FRIP_HOST
          : NotificationMessageSender.FRIP,
      };
      await this.getList();
    },
    async getList(): Promise<void> {
      try {
        this.disabled = true;
        this.filter = {
          createdAtTerm: this.searchFilter.createdAtTerm,
          mobileNumber: this.searchFilter.mobileNumber?.trim() || null,
          messageLike: this.searchFilter.messageLike || null,
          hostId: this.searchFilter.hostId || null,
          userId: this.searchFilter.userId || null,
          sender: this.searchFilter.sender,
        };
        await this.$apollo.queries.mnWise.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `알림톡 발송내역을 불러오는데 실패하였습니다. (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async showModalClick(row: MnWiseMessageAPIResponse): Promise<void> {
      this.showMnWiseMessageDetail = true;
      this.mnWiseMessageDetailModalData = row;
    },
    converMessageToSmall(message: string): string {
      return message.length > 50 ? message.slice(0, 50) + '...' : message;
    },
    convertDateToText(date: Date): string {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : '-';
    },
    convertMnWiseResultToText(record: MnWiseMessageRecord): string {
      if (!record) {
        return '-';
      }

      if (record.status === MnWiseMessageRetryState.DONE) {
        return '재발송 요청 완료';
      } else {
        switch (record.resultCode) {
          case 'AS':
            return '알림톡 발송 성공';
          case 'AF':
            return '알림톡 발송 실패';
          case 'SS':
            return '문자 발송 성공';
          case 'SF':
            return '문자 발송 실패';
          case 'EW':
            return '문자 발송 중, 내부 처리 중';
          case 'EF':
            return '시스템 실패 처리';
          case 'EE':
            return '시스템 오류';
          case 'EO':
            return '시스템 타임아웃';
          default:
            return record.status === MnWiseMessageRetryState.NONE
              ? '발송 실패'
              : '재발송 예정';
        }
      }
    },
  },
  apollo: {
    mnWise: {
      query: GET_NOTIFICATION_MESSAGES_QUERY,
      variables(): MnWiseMessagesRequestParam {
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        this.disabled = false;
        console.error(e);
      },

      async result(
        result: ApolloQueryResult<{
          mnWise: { mnWiseMessages: MnWiseMessageConnection };
        }>
      ): Promise<void> {
        this.disabled = false;
        this.totalRows = result.data.mnWise.mnWiseMessages.totalCount;
        this.mnWise.mnWiseMessages.edges = await Promise.all(
          result.data.mnWise.mnWiseMessages.edges
        );
      },
      update: data => data.mnWise,
    },
  },
});
