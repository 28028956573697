import gql from 'graphql-tag';

export const SAVING_MANUALLY_GIVE_POINT_MUTATION = gql`
  mutation SavingManuallyGivePoint($param: ManuallyGivePointParam!) {
    savingManuallyGivePoint(param: $param) {
      id
      memo
      worker {
        id
        nickname
      }
      createdAt
      updatedAt
      status
      reason
      points {
        totalCount
      }
    }
  }
`;

export const CANCEL_SAVED_POINT_MUTATION = gql`
  mutation CancelSavedPoint($param: CancelSavedPointParam!) {
    cancelSavedPoint(param: $param) {
      id
      kind
      amount
      refType
      refId
      cancelled
      deleted
      deletedAt
    }
  }
`;

export const GENERATE_S3_UPLOAD_INFO_MUTATION = gql`
  mutation GenerateS3UploadInfo($filename: String!) {
    generateS3UploadInfo(filename: $filename) {
      type
      url
      key
    }
  }
`;
