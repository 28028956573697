import gql from 'graphql-tag';

export const GET_NOTIFICATION_MESSAGES_QUERY = gql`
  query GetNotificationMessages(
    $page: Int
    $size: Int
    $filter: MnWiseMessageFilter
  ) {
    mnWise {
      mnWiseMessages(filter: $filter, size: $size, page: $page) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            sn
            customMessageSn
            message
            user {
              id
              nickname
            }
            host {
              id
              name
            }
            mobileNumber
            templateCode
            createdAt
            sender
            messageResult {
              id
              status
              resultCode
              alimtalkResultCode
              alimtalkResultMessage
              smsResultCode
              smsResultMessage
              sentAt
              receivedAt
            }
          }
        }
      }
    }
  }
`;
